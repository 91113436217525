import { useRouter } from 'next/router';

import { isBrowser } from '@tager/web-core';

export function usePageUrl(): string {
  const router = useRouter();

  return isBrowser()
    ? window.location.href
    : process.env.NEXT_PUBLIC_ORIGIN + router.asPath;
}
